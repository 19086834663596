<template>
  <div class="text">
    <div class="cont">
      <p class="title">
        “奋进正当时，砥砺再扬帆”劳动技能竞赛人员素质提升专题网络培训班培训心得（字数不限）
      </p>
      <div class="textArea">
        <textarea name="" id="" cols="30" rows="10" v-model="answer"></textarea>
      </div>
      <div class="btn" @click="subBtn()">提交</div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "TextContent",
  data() {
    return {
      answer: "",
    };
  },
  mounted() {},
  methods: {
    ...mapMutations(["ShowTipModal", "CloseTipModal"]), //登录弹窗

    //提交
    subBtn() {
      if (this.answer) {
        this.$axios
          .post(
            `/v1/personal/qaSave`,
            { answer: this.answer },
            { useLog: true }
          )
          .then((res) => {
            if (res.code == 0) {
              this.ShowTipModal({
                text: res.msg, //提示弹窗内容
                fun: function () {
                  this.$router.go(-1);
                },
              });
            }
          });
      } else {
        this.ShowTipModal({
          text: "请输入内容", //提示弹窗内容
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.text {
  height: 66vh;
  .cont {
    width: 1200px;
    margin: 0 auto;
    p.title {
      padding-top: 30px;
      font-size: 16px;
    }
    div.textArea {
      textarea {
        width: 800px;
        height: 200px;
        border: 1px solid #999;
        border-radius: 10px;
        resize: none;
        padding: 10px;
        font-size: 16px;
        margin: 40px 0 0;
      }
    }
    div.btn {
      width: 100px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #fff;
      border-radius: 5px;
      background-color: #409eff;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
</style>